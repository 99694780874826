import ReactDOM from "react-dom/client";
import "@fontsource/eb-garamond"; // Defaults to weight 400
import "@fontsource/eb-garamond/400.css"; // Specify weight
import "@fontsource/eb-garamond/400-italic.css"; // Specify weight and style
import "@fontsource/eb-garamond/800-italic.css"; // Specify weight and style

type ShouldUpdateScrollProps = {
  routerProps: {
    location: {
      pathname: string;
    };
  };
  prevRouterProps: {
    location: {
      pathname: string;
    };
  };
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}: ShouldUpdateScrollProps): boolean => {
  const prevPathname = prevRouterProps ? prevRouterProps.location.pathname : "";
  return !(
    ["/careers/", "/products/"].includes(location.pathname) &&
    prevPathname === location.pathname
  );
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
